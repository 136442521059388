<template>
  <div class="container">
    <img :src="$getConfig('activityNotStartBg')" width="100%" alt="" />
    <BaseNav
      :indexs="[
        {
          index: 1,
          tracking: { userVisit: '42', sourceFlag: '1' }
        },
        {
          index: 2,
          tracking: { event: 'launch', userVisit: '42', sourceFlag: '2' }
        }
      ]"
    />
  </div>
</template>

<script>
import preventBack from '@/mixins/prevent-browser-back'

export default {
  mixins: [preventBack],
  name: 'ActivityNotStart'
}
</script>

<style lang="scss" scoped>
.container {
  img {
    display: block;
    width: 100%;
  }
}
</style>
